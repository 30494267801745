import React, { Component } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Prando from 'prando'
import { connect } from "redux-zero/react";

import actions from "../store/actions";
import { Button, Header, Paragraph, Container, AnswerContainer, DetailContainer, Key, Value } from '@hank/hank-components'
import { createSummary } from '@hank/hank-services';
import contacts from "../contacts";

class AnswerSummary extends Component {
  render() {
    return (
      <AnswerContainer>
        <Header>Vastaukset</Header>
        {this.props.values.map((pair, i) => {
          return(
            <Container key={i}>
              <Key>{pair.key}</Key>
              <Value>{pair.value}</Value>
            </Container>
          )}
        )}
      </AnswerContainer>
    )
  }
}

class Simulation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      price: '',
      priceMin: '',
      priceMax: '',
      qualityMin: '',
      qualityMax: '',
      qualityWeight: '',
      bids: '',
      formula: undefined
    }
  }

  sendSummary = () => {
    const email = prompt("Anna sähköpostiosoitteesi");
    return email ? createSummary(email, '-', contacts.juholehtoviita, this.state, true) : null;
  };

  calculate(max, min, i) {
    const random = new Prando(i*1000).next(0.01, 1)
    return Math.floor((parseFloat(max)-parseFloat(min))*random+parseFloat(min))
  }

  getBids(values) {
    let bids = []
    for (let i=0; i<values.bids.value; i++) {
      bids.push({
        price: this.calculate(values.priceMax.value, values.priceMin.value, values.priceMax.value*values.priceMin.value*(i+1)),
        quality: this.calculate(values.qualityMax.value, values.qualityMin.value, values.priceMax.value/values.priceMin.value*(i+1))
      })
    }
    bids.sort((a,b) => { return a.price-b.price })

    if (bids.length === 0) return []

    const maxQuality = bids.map(bid => bid.quality).reduce((maxBidQuality, bidQuality) => bidQuality >= maxBidQuality ? bidQuality : maxBidQuality)
    const minPrice = bids.map(bid => bid.price).reduce((minBidPrice, bidPrice) => bidPrice <= minBidPrice ? bidPrice : minBidPrice)

    return bids.map((bid, index) => {
      switch(values.formula) {
        case 'Laadun maksimoinnin kaava':
          return this.qualityMaxFormula(bid.price, bid.quality, minPrice, maxQuality, parseFloat(values.qualityWeight.value))
        default:
          return this.traditionalFormula(bid.price, bid.quality, minPrice, maxQuality, parseFloat(values.qualityWeight.value))
      }
    });
  }

  traditionalFormula(bidPrice, bidQuality, minPrice, maxQuality, qualityWeight) {
    const pricePoint = (minPrice / bidPrice) * (100 - qualityWeight)
    const qualityPoint = (bidQuality / maxQuality) * qualityWeight
    const sum = parseFloat((pricePoint + qualityPoint).toFixed(2))

    return {
      price: bidPrice,
      quality: bidQuality,
      pricePoint: parseFloat(pricePoint.toFixed(2)),
      qualityPoint: parseFloat(qualityPoint.toFixed(2)),
      sum,
      pricePerPoint: parseFloat((bidPrice / sum).toFixed(2)),
    }
  }

  qualityMaxFormula(bidPrice, bidQuality, minPrice, maxQuality, qualityWeight) {
    const pricePoint = (minPrice / bidPrice) * (100 - qualityWeight)
    const qualityPoint = (bidQuality / 50) * qualityWeight
    const sum = parseFloat((pricePoint + qualityPoint).toFixed(2))

    return {
      price: bidPrice,
      quality: bidQuality,
      pricePoint: parseFloat(pricePoint.toFixed(2)),
      qualityPoint: parseFloat(qualityPoint.toFixed(2)),
      sum,
      pricePerPoint: parseFloat((bidPrice / sum).toFixed(2)),
    }
  }

  componentDidMount() {
    const { steps, setLastSimulation } = this.props

    let { price, priceMin, priceMax, qualityMin, qualityMax, qualityWeight, bids, formula } = steps
    price = price ? price : { value: "1200" }
    priceMin = priceMin ? priceMin : { value: "700" }
    priceMax = priceMax ? priceMax : { value: "1300" }
    qualityMin = qualityMin ? qualityMin : { value: "15" }
    qualityMax = qualityMax ? qualityMax : { value: "40" }
    qualityWeight = qualityWeight ? qualityWeight : { value: "80" }
    bids = bids ? bids : { value: "10" }

    if (!steps.formula) {
      formula = 'Perinteinen kaava'
    } else {
      formula = steps['formula-choices'].message
    }

    const calculatedBids = this.getBids({ price, priceMin, priceMax, qualityMin, qualityMax, qualityWeight, bids, formula });

    const highestBid = calculatedBids.reduce((highest, bid) => {
      return (highest.sum || 0) > bid.sum ? highest : bid
    });

    const params = { price, priceMin, priceMax, qualityMin, qualityMax, qualityWeight, bids, calculatedBids, formula, highestBid };
    this.setState(params);
    setLastSimulation(params);
  }

  render() {
    const { price, priceMin, priceMax, qualityMin, qualityMax, qualityWeight, bids, calculatedBids, formula, highestBid} = this.state;

    const values = [
      {
        key: 'Arvo',
        value: price.value + ' €'
      },
      {
        key: 'Hinta minimi',
        value: priceMin.value + ' €'
      },
      {
        key: 'Hinta maksimi',
        value: priceMax.value + ' €'
      },
      {
        key: 'Laatu minimi',
        value: qualityMin.value
      },
      {
        key: 'Laatu maksimi',
        value: qualityMax.value
      },
      {
        key: 'Laadun painoarvo',
        value: qualityWeight.value
      },
      {
        key: 'Tarjousten määrä',
        value: bids.value
      }
    ]

    return (
      <DetailContainer>
        <AnswerSummary
          values={values}
        />
        <Container>
          <Header>Tarjoukset</Header>
          <Paragraph>{formula}</Paragraph>
          <ReactTable
            data={calculatedBids}
            resolveData={data => data.map(row => {
              row.price = row.price + ' €'
              row.pricePerPoint = row.pricePerPoint + ' €'
              return row
            })}
            pageSize={this.state.bids.value <= 10 ? this.state.bids.value : 10}
            showPagination={this.state.bids.value <= 10 ? false : true}
            getTrProps={(state, rowInfo, column) => {
              if (!rowInfo) return { style: { background: "" } }
              return {
                style: {
                  background: rowInfo.row['sum'] === highestBid['sum'] ? "#2bc732" : "#ededed"
                }
              };
            }}
            columns={[{
              id: 'index',
              Header: '',
              accessor: d => (calculatedBids.indexOf(d) + 1) + '. halvin',
              minWidth: 25
            }, {
              Header: 'Hinta',
              accessor: 'price',
              minWidth: 50
            }, {
              Header: 'Laatu',
              accessor: 'quality',
              minWidth: 50
            }, {
              Header: 'Hintapisteet',
              accessor: 'pricePoint',
              minWidth: 50
            }, {
              Header: 'Laatupisteet',
              accessor: 'qualityPoint',
              minWidth: 50
            }, {
              Header: 'Summa',
              accessor: 'sum',
              minWidth: 50
            }, {
              Header: 'Pisteen hinta',
              accessor: 'pricePerPoint',
              minWidth: 50
            }]}
          />
        </Container>
        <Container style={{float: 'right', marginTop: '10px'}}>
          <Button onClick={this.sendSummary}>Lähetä simulaatio sähköpostiini</Button>
        </Container>
      </DetailContainer>
    );
  }
}

export default connect(null, actions)(Simulation);
