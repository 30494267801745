const Config = {
  firebase: {
    apiKey: "AIzaSyB9ud80sG03Sip6UnxBvY_x6faGQT6B6qE",
    authDomain: "ptcservices.firebaseapp.com",
    databaseURL: "https://ptcservices.firebaseio.com",
    projectId: "ptcservices",
    storageBucket: "ptcservices.appspot.com",
    messagingSenderId: "215848715485"
  }
};

export default Config;
