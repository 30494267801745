import React from 'react'
import { Detail } from '@hank/hank-components'
// import { secureStep } from '@hank/hank-services';

import SummaryCard from '../components/summary'
import Introduction from './introduction'
import Vertailukone from './vertailukone'

const Steps = Introduction.concat([
  {
    id: 'nav-phase',
    message: 'Mistä hankinnan vaiheesta haluaisit tietoja?',
    trigger: 'nav-phase-choices'
  },
  {
    id: 'nav-phase-choices',
    options: [
      { value: 1, label: 'Hankinnan suunnittelu', trigger: 'nav-planning'},
      // { value: 2, label: 'Tarjouspyynnön viimeistely', trigger: 'nav-bidding'},
      // { value: 3, label: 'Hankintapäätöksen laatiminen', trigger: 'nav-purchase'},
      { value: 4, label: 'Sopimuskausi', trigger: 'nav-contract'},
      // { value: 5, label: 'Vertailukone', trigger: () => secureStep({password: 'hankitar', onAccept: 'vertailukone-1', onReject: 'nav-phase'}),}
    ],
  },
  {
    id: 'viimeistely',
    message: 'Käyttäjä tarvitsee apua tarjouskilpailun viimeistelyssä.',
    end: true
  },
  {
    id: 'sopimuskausi',
    message: 'Käyttäjä pähkäilee sopimuskauden kanssa.',
    end: true
  },
  {
    id: 'detail',
    component: <Detail />,
    waitAction: true,
    trigger: '1',
  },
  {
    id: 'summary',
    component: <SummaryCard />,
    waitAction: true
  }
]).concat(Vertailukone)

const getChoices = (treeArr) => {
  let choices = []
  let phase = undefined

  let options = treeArr.map((tree, i) => {
    if (!phase) phase = tree['phase']
    return { value: i+1, label: tree['treeName'], trigger: `${tree['uuid']}-question-1` }
  })
  options.push({ value: options.length+1, label: 'Siirry hankinnan eri vaiheisiin', trigger: 'nav-phase' })

  let choice = {
    id: `nav-${phase}`,
    trigger: `nav-${phase}-choices`
  }

  switch (phase) {
    case 'planning':
      choice['message'] = 'Suunnitteluvaiheessa liittyy seuraavat kysymykset'
      break
    case 'bidding':
      choice['message'] = 'Tarjouspyynnön viimeistelyyn liittyy seuraavat kysymykset'
      break
    case 'purchase':
      choice['message'] = 'Hankintapäätöksen laatimiseen liittyy seuraavat kysymykset'
      break
    case 'contract':
      choice['message'] = 'Sopimuskauteen liittyy seuraavat kysymykset'
      break
    default:
      choice['message'] = 'Tämä on virhe, ota yhteyttä palveluntuottajaan, jos näet tämän viestin.'
  }

  choices.push(choice)
  choices.push({
    id: `nav-${phase}-choices`,
    options: options
  })

  return choices
}

export { Steps, getChoices };
