import React, {Component} from 'react';

import ChatBot from 'react-simple-chatbot';
import queryString from 'query-string';
import { ThemeProvider } from 'styled-components';
import { Provider } from "redux-zero/react";
import { LanguageSelector, countryToLanguage, SpeechSelector, withFirebase } from '@hank/hank-components';
import { decodePreview, InternalizationService, translateGraph } from '@hank/hank-services';

import store from "./store/store";
import avatar from './hank_icon.svg';
import { Steps, getChoices } from './steps/steps'


const styling = {
  avatarStyle: {
    height: '36px'
  },
  botAvatar: avatar,
  bubbleOptionStyle: {
    background: '#2bc732',
    color: '#fbfbfb'
  },
  contentStyle: {
    marginTop: 0,
    paddingTop: 0
  },
  customStyle: {
    background: '#ededed',
    justifyContent: 'left',
    padding: 0,
    margin: 0,
    boxShadow: '0px 0px 0px 0px'
  },
  hideUserAvatar: true,
  theme: {
    background: '#ededed',
    fontFamily: 'Roboto',
    headerBgColor: '#1f2123',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#fbfbfb',
    botFontColor: '#3e3e3e',
    userBubbleColor: '#3e3e3e',
    userFontColor: '#ededed',
  }
}

const botOptions = {
  width: `${window.innerWidth}px`,
  height: `${window.innerHeight}px`,
  placeholder: 'Kirjoita viesti ...',
  headerTitle: ''
}

class Hank extends Component {

  state = {
    steps: null,
    loading: true,
    speech: null
  };

  getDatabaseContent = async () => {
    const db = await this.props.firebase.db.ref('/').once('value');

    const contacts = db.val()['contacts'];
    const graphs = db.val()['graphs'];

    let trees = []
    for (let phase in graphs) {
      if (phase !== 'phase') {
        let phaseTrees = []
        for (let tree in graphs[phase]) {
          phaseTrees.push(graphs[phase][tree])
          trees = trees.concat(translateGraph(graphs[phase][tree]))
        }
        trees = trees.concat(getChoices(phaseTrees))
      }
    }

    console.log(`Fetched ${trees.length} steps.`)

    return { contacts, trees }
  };

  // Check if preview content exists in URL query param and combine internal & db originated steps
  setSteps = async () => {
    const previewContent = queryString.parse(window.location.search).preview;
    const databaseContent = await this.getDatabaseContent();
    const internalContent = Steps;
    const steps = previewContent ?
            translateGraph(decodePreview(previewContent)).concat(internalContent).concat(databaseContent.trees)
            : internalContent.concat(databaseContent.trees);
    this.setState({steps, loading: false});
  };

  // Check if language exists in URL query param
  setLanguage = () => InternalizationService.setLanguage(queryString.parse(window.location.search).language);

  setSpeech = () => {
    this.setState({ speech: queryString.parse(window.location.search).speech === 'true' ? true : false })
  }

  refreshView = ({ speech }) => {
    const params = queryString.parse(window.location.search);
    params.language = InternalizationService.getLanguage();
    params.speech = speech === true ? !this.state.speech : this.state.speech
    window.location.href = `//${window.location.host}${window.location.pathname}?${Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')}`;
  };

  onSelectLanguage = (countryCode) => {
    const language = countryToLanguage(countryCode);
    InternalizationService.setLanguage(language);
    this.refreshView({ speech: false });
  };

  onSelectSpeech = () => {
    this.refreshView({ speech: true });
  }

  componentDidMount() {
    localStorage.removeItem('rsc_cache');

    this.setSteps();
    this.setLanguage();
    this.setSpeech();
    this.handleEnd = this.handleEnd.bind(this);
  }

  handleEnd({ renderedSteps, steps, values }) {
    console.log(renderedSteps, steps, values);
    // The renderedSteps only reachable, when the end-flag is set to true in a step.
    // However, this does not allow to continue the conversation.

    // console.log(values);
    // alert(`Chat handleEnd callback! Number: ${values[0]}`);
  }

  render() {
      console.log('window height:', window.innerHeight);
      console.log('window width:', window.innerWidth);
    return (
      this.state.loading ? <React.Fragment/> :
        <Provider store={store}>
          <ThemeProvider theme={styling.theme}>
            <React.Fragment>
              <LanguageSelector defaultLanguage={InternalizationService.getLanguage()}
                                onSelect={this.onSelectLanguage}/>
              <SpeechSelector lang={InternalizationService.getLanguage()}
                              enable={this.state.speech}
                              onClick={this.onSelectSpeech}/>
              <ChatBot
                speechSynthesis={{enable: this.state.speech, lang: InternalizationService.getLanguage()}}
                handleEnd={this.handleEnd}
                cache={true}
                width={botOptions.width}
                height={botOptions.height}
                placeholder={botOptions.placeholder}
                headerTitle={botOptions.headerTitle}
                steps={this.state.steps}
                bubbleOptionStyle={styling.bubbleOptionStyle}
                hideUserAvatar={styling.hideUserAvatar}
                botAvatar={styling.botAvatar}
                avatarStyle={styling.avatarStyle}
                customStyle={styling.customStyle}
                contentStyle={styling.contentStyle}
              />
            </React.Fragment>
          </ThemeProvider>
        </Provider>
    );
  }
}

const App = withFirebase(Hank);

export default App;
