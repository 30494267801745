import React from 'react'
import Simulation from '../components/simulation'

const Vertailukone = [
  {
    id: 'vertailukone-1',
    message: ({ previousValue, steps }) => {
      return steps.price ? `Edellinen arviosi hankinnan hinnasta oli ${steps.price.value}, mikä on uusi arviosi?` : 'Arvioi hankintasi hintaa.'
    },
    trigger: 'price'
  },
  {
    id: 'price',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      return true
    },
    trigger: ({value, steps}) => {
      return steps['change-value-choices'] ? 'simulation' : 'vertailukone-2'
    }
  },
  {
    id: 'vertailukone-2',
    message: ({ previousValue, steps }) => {
      return steps.priceMin ? `Edellinen arviosi pienimmästä hinnasta oli ${steps.priceMin.value}, mikä on uusi arviosi?` : 'Arvioi pienintä hintaa, minkä odotat saavasi.'
    },
    trigger: 'priceMin'
  },
  {
    id: 'priceMin',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      return true
    },
    trigger: ({value, steps}) => {
      return steps['change-value-choices'] ? 'simulation' : 'vertailukone-3'
    }
  },
  {
    id: 'vertailukone-3',
    message: ({ previousValue, steps }) => {
      return steps.priceMax ? `Edellinen arviosi suurimmasta hinnasta oli ${steps.priceMax.value}, mikä on uusi arviosi?` : 'Arvioi suurinta hintaa, minkä odotat saavasi.'
    },
    trigger: 'priceMax'
  },
  {
    id: 'priceMax',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      return true
    },
    trigger: ({value, steps}) => {
      return steps['change-value-choices'] ? 'simulation' : 'vertailukone-4'
    }
  },
  {
    id: 'vertailukone-4',
    message: ({ previousValue, steps }) => {
      return steps.qualityMin ? `Edellinen arviosi laatupisteiden vähimmäismäärästä oli ${steps.qualityMin.value}, mikä on uusi arviosi? (kattopisteet 50)` : 'Arvioi laatupisteiden vähimmäismäärää, minkä odotat saavasi (kattopisteet 50).'
    },
    trigger: 'qualityMin'
  },
  {
    id: 'qualityMin',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      if (value > 50) {
        return 'Laatupisteet ei saa olla ylittää 50'
      }
      if (value < 0) {
        return 'Laatupisteiden pitää olla vähintään 0'
      }
      return true
    },
    trigger: ({value, steps}) => {
      return steps['change-value-choices'] ? 'simulation' : 'vertailukone-5'
    }
  },
  {
    id: 'vertailukone-5',
    message: ({ previousValue, steps }) => {
      return steps.qualityMax ? `Edellinen arviosi laatupisteiden enimmäismäärästä oli ${steps.qualityMax.value}, mikä on uusi arviosi? (kattopisteet 50)` : 'Arvioi laatupisteiden enimmäismäärää, minkä odotat saavasi (kattopisteet 50).'
    },
    trigger: 'qualityMax'
  },
  {
    id: 'qualityMax',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      if (value > 50) {
        return 'Laatupisteet ei saa olla ylittää 50'
      }
      if (value < 0) {
        return 'Laatupisteiden pitää olla vähintään 0'
      }
      // TODO: qualityMax >= qualityMin
      return true
    },
    trigger: ({value, steps}) => {
      return steps['change-value-choices'] ? 'simulation' : 'vertailukone-6'
    }
  },
  {
    id: 'vertailukone-6',
    message: ({ previousValue, steps }) => {
      return steps.qualityWeight ? `Edellinen painoarvo laadulle oli ${steps.qualityWeight.value}, mikä on uusi arviosi? (vs hinnalle, yhteensä max 100p)` : 'Kuinka suuren painoarvon haluat laadulle? (vs hinnalle, yhteensä max 100p)'
    },
    trigger: 'qualityWeight'
  },
  {
    id: 'qualityWeight',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      if (value > 100) {
        return 'Painoarvo saa olla enintään 100'
      }
      if (value < 0) {
        return 'Painoarvo pitää olla vähintään 0'
      }
      return true
    },
    trigger: ({value, steps}) => {
      return steps['change-value-choices'] ? 'simulation' : 'vertailukone-7'
    }
  },
  {
    id: 'vertailukone-7',
    message: ({ previousValue, steps }) => {
      return steps.bids ? `Edellisen kerran simuloit ${steps.bids.value} tarjousta, monta tarjousta haluaisit nyt simuloida?` : 'Kuinka monta tarjousta haluat simuloida?'
    },
    trigger: 'bids'
  },
  {
    id: 'bids',
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return 'Syötä numeroarvo'
      }
      return true
    },
    trigger: 'simulation'
  },
  {
    id: 'vertailukone-8',
    message: 'Haluatko muuttaa jotain tarjouksen arvoa, kokeilla muuta kaavaa vai palata hankinnan vaiheisiin?',
    trigger: 'nav-simulation'
  },
  {
    id: 'nav-simulation',
    options: [
      {
         value: 0,
         label: "Vaihda tarjouksen kaavaa",
         trigger: "formula"
      },
      {
          value: 1,
          label: "Muuta syötettyjä arvoja",
          trigger: "change-values"
       },

       {
          value: 2,
          label: "Palaa hankinnan vaiheisiin",
          trigger: "nav-phase"
       }
    ]
  },
  {
    id: 'formula',
    message: 'Voit kokeilla arvioida tarjouksia seuraavilla kaavoilla',
    trigger: 'formula-choices'
  },
  {
    id: 'formula-choices',
    options: [
       {
          value: 0,
          label: "Perinteinen kaava",
          trigger: "simulation"
       },
       {
          value: 1,
          label: "Laadun maksimoinnin kaava",
          trigger: "simulation"
       }
    ]
  },
  {
    id: 'change-values',
    message: 'Mitä arvoa haluaisit muuttaa?',
    trigger: 'change-value-choices'
  },
  {
    id: 'change-value-choices',
    options: [
       {
          value: 0,
          label: "Hankinnan hintaa",
          trigger: "vertailukone-1"
       },
       {
          value: 1,
          label: "Hinnan minimiä",
          trigger: "vertailukone-2"
       },
       {
          value: 2,
          label: "Hinnan maksimia",
          trigger: "vertailukone-3"
       },
       {
          value: 3,
          label: "Laadun minimiä",
          trigger: "vertailukone-4"
       },
       {
          value: 4,
          label: "Laadun maksimia",
          trigger: "vertailukone-5"
       },
       {
          value: 5,
          label: "Laadun painoarvoa",
          trigger: "vertailukone-6"
       },
       {
          value: 6,
          label: "Tarjousten määrää",
          trigger: "vertailukone-7"
       }
    ]
  },
  {
    id: 'simulation',
    component: <Simulation />,
    trigger: 'vertailukone-8'
  }
]

export default Vertailukone;
