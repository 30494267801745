import React, { Component } from 'react';

import { Loading } from 'react-simple-chatbot';
import {
  Paragraph,
  Container,
  DetailContainer,
  CardContainer,
  CardHeader,
  CardImage,
  CardTitle,
  Button,
  Link,
  SummaryLogo,
  withFirebase
} from '@hank/hank-components';

// import { createSummary } from '@hank/hank-services';
import logo from '../ptcs_logo2.svg'

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      result: '',
      continue: true,
      contacts: {
        name: 'Juho Lehtoviita',
        imageUrl: 'https://ptcs.fi/wp-content/uploads/2017/06/1508-ptc-juho_0125-180x240.jpg',
        titles: 'Asiantuntija, YTM',
        phone: '+358 50 322 4498',
        email: 'juho.lehtoviita@ptcs.fi',
        areaOfExpertise: 'Julkiset, KV-hankinnat'
      }
    };
  }

  getContacts = async () => {
    const db = await this.props.firebase.db.ref('/contacts').once('value');
    this.setState({ loading: false, contacts: db.val() })
  }

  componentDidMount() {
    this.getContacts();
    this.setState({ result: this.props.previousStep.message, continue: true })
  }

  // sendSummary = () => {
  //   const email = prompt("Anna sähköpostiosoitteesi");
  //   return email ? createSummary(email, this.state.result, this.state.contacts[this.props.previousStep.metadata.contact], null) : null;
  // };

  render() {
    const { loading, result, contacts } = this.state;
    return (
      <DetailContainer>
        <Link href="https://www.ptcs.fi" target="_blank">
          <SummaryLogo src={logo}/>
        </Link>
        <Paragraph>Antamanne vastauksien perusteella: <strong>{loading ? <Loading/> : result}</strong></Paragraph>
        <em>Mikäli haluatte lisätietoja, voitte ottaa asiantuntijaamme yhteyttä.</em>
        { loading ? <Loading/> :
          <CardContainer>
            <CardImage src={contacts[this.props.previousStep.metadata.contact].imageUrl}/>
            <Container>
              <CardHeader>{contacts[this.props.previousStep.metadata.contact].name}</CardHeader>
              <CardTitle>{contacts[this.props.previousStep.metadata.contact].titles}</CardTitle>
              <Paragraph>{contacts[this.props.previousStep.metadata.contact].phone}</Paragraph>
              <Paragraph>{contacts[this.props.previousStep.metadata.contact].email}</Paragraph>
              <Paragraph><strong>Erityisosaaminen:</strong>&nbsp;{contacts[this.props.previousStep.metadata.contact].areaOfExpertise}</Paragraph>
            </Container>
          </CardContainer>
        }
        { this.state.continue ?
          <Container style={{float: 'right', marginTop: '10px'}}>
            <Button onClick={() => {
              this.props.triggerNextStep({
                value: 1,
                trigger: this.props.previousStep.metadata.navigation
              });
              this.setState({continue: false})
            }}>Jatka keskustelua
            </Button>
          </Container> : null }
      </DetailContainer>
    );
  }
}

const SummaryCard = withFirebase(Summary)
export default SummaryCard;
