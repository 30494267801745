import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Hank';
// import { verifyPassword } from '@hank/hank-services';
import Config from './config';
import { Firebase, FirebaseContext } from '@hank/hank-components';

// if(process.env.NODE_ENV!=='development') {
//     while (!verifyPassword(atob('aGFua2tpamE=')));
// }

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase(Config.firebase)} >
    <App/>
  </FirebaseContext.Provider>,
  document.getElementById('root'));
