import React from 'react'
import { InternalizationService } from '@hank/hank-services';
import Intro from '../components/intro'


const Introduction = [
  {
    id: 'intro',
    component: <Intro />,
    delay: 0,
    trigger: '3'
  },
  {
    id: '1',
    message: () => InternalizationService.translateStep({fi: 'Hei, minä olen Hankintabotti Hank!', en: 'Hi, I am Hank the procurement bot!'}),
    trigger: '2',
  },
  {
    id: '2',
    message: 'Tarkoitukseni on selittää ja havainnollistaa julkisten hankintojen tarjousprosessia sekä laadun ja hinnan vertailua.',
    trigger: '3',
  },
  {
    id: '3',
    message: () => InternalizationService.translateStep({fi: 'Mikä sinun nimesi on?', en: 'What is your name?'}),
    trigger: '4',
  },
  {
    id: '4',
    user: true,
    trigger: '5',
  },
  {
    id: '5',
    message: () => InternalizationService.translateStep({fi: 'Hei {previousValue}, hauska tavata!', en: 'Hello {previousValue}, nice to meet you!'}),
    trigger: 'nav-phase'
  },
]

export default Introduction;
