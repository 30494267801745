import React, { Component } from 'react';
import { IntroContainer, IntroHeader, IntroImage, IntroSubtitle, IntroParagraph, IntroLogo, Link } from '@hank/hank-components';
import avatar from '../hank_intro.svg';
import logo from '../ptcs_logo.svg';

class Intro extends Component {
  render() {
    return (
      <IntroContainer>
        <IntroImage src={avatar}/>
        <IntroHeader>Hank</IntroHeader>
        <IntroSubtitle>HANKINTABOTTI</IntroSubtitle>
        <IntroParagraph>Hei, minä olen Hankintabotti Hank! Tarkoitukseni on selittää ja havainnollistaa julkisten hankintojen tarjousprosessia sekä laadun ja hinnan vertailua.</IntroParagraph>
        <Link href="https://www.ptcs.fi" target="_blank">
          <IntroLogo src={logo}/>
        </Link>
      </IntroContainer>
    );
  }
}

export default Intro
